import React from "react";
import { useRef } from "react";
import ReactDOM from "react-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  CssBaseline,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  ThemeProvider,
  createTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import background from "./assets/quiddity-background-v3.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import { CardActionArea } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#dc004e",
    },
    text: {
      primary: "#000000", // Set primary text color to white
      secondary: "#ffffff", // Set secondary text color to white
    },
  },
});

const products = [
  {
    id: 1,
    name: "The forever rose",
    description: "A rose that lasts forever.",
    price: 399,
    image:
      "https://minis-media-assets.swiggy.com/swiggymini/image/upload/w_1024,h_1024,c_fit,fl_lossy,q_auto:eco,f_auto/IMAGE/361e2bf9-cd2d-4f9f-ac79-7e39abae104e/Abe4Bdy3NL1NCGek8mKWy",
    link: "https://quiddity.mini.store/products/b070396b-897b-4bc4-9c7e-f42cffee4040?slug=quiddity",
  },
  {
    id: 2,
    name: "Canvas Art",
    description: "Hand painted canvas art.",
    price: 400,
    image:
      "https://minis-media-assets.swiggy.com/swiggymini/image/upload/w_1024,h_1024,c_fit,fl_lossy,q_auto:eco,f_auto/IMAGE/72ea1031-97fc-4180-acee-dcab7632810a/NcNLQaIetVXqDD-IJRc9k",
    link: "https://quiddity.mini.store/products/6b3cb69c-b79b-45cc-8468-c6ed4510c5c4?slug=quiddity",
  },
  {
    id: 3,
    name: "The Cresent Set",
    description: "A set of crescent shaped pendentas.",
    price: 999,
    image:
      "https://minis-media-assets.swiggy.com/swiggymini/image/upload/w_1024,h_1024,c_fit,fl_lossy,q_auto:eco,f_auto/IMAGE/c45a5d28-2f6d-46c2-8fd7-c88371bbf6fb/HKoaWzCVbbwY-UmKD9xTW-027FEFB8-08B5-4553-A9F3-09486F56EB55.jpg",
    link: "https://quiddity.mini.store/products/3920319d-338d-4b79-b453-9db42b130faf?slug=quiddity",
  },
  {
    id: 4,
    name: "Pooja Thali",
    description: "A beautiful pooja thali set.",
    price: 899,
    image:
      "https://minis-media-assets.swiggy.com/swiggymini/image/upload/w_1024,h_1024,c_fit,fl_lossy,q_auto:eco,f_auto/IMAGE/916b8c3e-c8cf-4a94-ac4f-219eb6f538f8/h9DrgTavXALTLnCa4TFGb-32DA4114-6BDF-464F-B2DC-4625CA95449B.jpg",
    link: "https://quiddity.mini.store/products/d2f3a4be-91df-4c74-8329-dcbbf421e4a9?slug=quiddity",
  },
  {
    id: 5,
    name: "Rudraksha Rakhi",
    price: 220,
    description: "A beautiful Rudraksha Rakhi",
    image:
      "https://minis-media-assets.swiggy.com/swiggymini/image/upload/w_1024,h_1024,c_fit,fl_lossy,q_auto:eco,f_auto/IMAGE/41e99f5a-b18a-496a-86ab-864604a60976/cbwELBromqmEc-rchTS5v",
    link: "https://quiddity.mini.store/products/f8f1fe67-42e0-4c57-87ec-a1d103c4c5dc?slug=quiddity",
  },
];

function ProductCard({ product }) {
  return (
    <Card sx={{ height: "100%" }}>
      <CardActionArea component="a" href={product.link}>
        <CardMedia
          component="img"
          sx={{ height: { xs: 140, sm: 180, md: 200, lg: 220, xl: 240 } }}
          image={product.image}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            sx={{ fontFamily: "fantasy" }}
            component="div"
          >
            {product.name}
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: "fantasy" }}>
            {product.description}
          </Typography>
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Divider />
            <Typography
              variant="body1"
              component="div"
              color="textPrimary"
              sx={{ mt: 1, fontWeight: "bold", color: "textPrimary" }}
            >
              ₹{product.price}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function App() {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="fixed" color="inherit">
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 1,
          }}
        >
          <Box sx={{ textAlign: "center", flexGrow: 1 }}>
            <Typography
              variant="h4"
              component="div"
              color="textPrimary"
              sx={{ fontFamily: "fantasy" }}
            >
              Quiddity
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              color="textPrimary"
              sx={{ fontFamily: "fantasy" }}
            >
              ~Handcrafted With Heart~
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 0,
            }}
          >
            <IconButton
              href="https://www.instagram.com/__quiddity__?igsh=MTFsNXJ1c2J1cGlnZA%3D%3D"
              color="inherit"
              sx={{ marginRight: 2 }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <InstagramIcon sx={{ fontSize: 32, color: "#E1306C" }} />
                <Typography variant="caption">Instagram</Typography>
              </Box>
            </IconButton>
            <IconButton
              href="https://quiddity.mini.store/?path=%2F"
              color="inherit"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <ShoppingCartIcon sx={{ fontSize: 32 }} />
                <Typography variant="caption">Store</Typography>
              </Box>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          height: "calc(100vh - 64px)", // Adjust height based on AppBar height
          overflowY: "auto",
          paddingTop: "64px", // Adjust padding to account for AppBar height
        }}
      >
        <Container sx={{ paddingTop: 8, display: "flex", paddingBottom: 8 }}>
          <Button onClick={scrollLeft}>
            <ArrowBackIosIcon />
          </Button>
          <Box
            ref={scrollRef}
            sx={{ display: "flex", overflowX: "auto", mb: 4 }}
          >
            {products.map((product, index) => (
              <Box key={index} sx={{ minWidth: 300, mr: 2 }}>
                <ProductCard product={product} />
              </Box>
            ))}
          </Box>
          <Button onClick={scrollRight}>
            <ArrowForwardIosIcon />
          </Button>

          {/* <Grid container spacing={2}>
            {products.map((product, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
                <ProductCard product={product} />
              </Grid>
            ))}
          </Grid> */}
        </Container>
        <Container sx={{ paddingTop: 4, paddingBottom: 4 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>About Us</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {/* Add your About Us content here */}
                We are a company dedicated to providing the best products and
                services to our customers.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Cancellation Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {/* Add your Cancellation Policy content here */}
                Our cancellation policy allows you to cancel orders within 24
                hours of purchase.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Contact Us</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {/* Add your Contact Us content here */}
                You can reach us at contact@quiddity.com or call us at (123)
                456-7890.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
